import $ from 'jquery'

import { ga } from '../utility'
import toggles from './toggles'

export default function() {
  const $win = $(window)

  let menuScrollbyThreshold = 240
  let fixedMenu = false
  let showingMenu = false

  const $menu = $('.masthead')

  let scrollInfo = {
    posY: 0,
    prevPosY: undefined,
    menuHeight: 0
  }

  $menu.after('<div class="masthead__placeholder" aria-hidden />')

  const currentMenuHeight = () => $menu.outerHeight()

  document.documentElement.style.setProperty(
    '--menuHeight',
    `${currentMenuHeight()}px`
  )

  $win.on('scroll', () => {
    scrollInfo = {
      ...scrollInfo,
      posY: $win.scrollTop(),
      prevPosY: scrollInfo.posY
    }

    window.requestAnimationFrame(() => {
      if (scrollInfo.posY > scrollInfo.prevPosY) {
        // scrolling down
        if (
          !fixedMenu &&
          scrollInfo.posY > currentMenuHeight() + menuScrollbyThreshold
        ) {
          scrollInfo = { ...scrollInfo, menuHeight: currentMenuHeight() }
          $('.masthead__placeholder')
            .css('height', scrollInfo.menuHeight)
            .addClass('is-showing')
          $menu.addClass('is-fixed')
          fixedMenu = true
          document.documentElement.style.setProperty(
            '--menuHeight',
            `${scrollInfo.menuHeight}px`
          )
        } else if (
          fixedMenu &&
          showingMenu &&
          !$('body').hasClass('force-masthead')
        ) {
          showingMenu = false
          $menu.removeClass('is-revealed')
        }
      } else if (scrollInfo.posY < scrollInfo.prevPosY) {
        // scrolling up
        if (
          fixedMenu &&
          scrollInfo.posY <=
            Math.max(scrollInfo.menuHeight - currentMenuHeight(), 0) &&
          !$('body').hasClass('force-masthead')
        ) {
          $menu.removeClass('is-fixed is-revealed')
          $('.masthead__placeholder').removeClass('is-showing')
          fixedMenu = false
          showingMenu = false
        } else if (
          fixedMenu &&
          !showingMenu &&
          scrollInfo.posY > scrollInfo.menuHeight + menuScrollbyThreshold
        ) {
          showingMenu = true
          $menu.addClass('is-revealed')
        }
      }
    })
  })

  let hasOpenSearch = false
  let hasOpenMenu = false

  const temporarilyPreventMenuHiding = () => {
    $('body').addClass('force-masthead')
    setTimeout(() => {
      $('body').removeClass('force-masthead')
    }, 2000)
  }

  const toggleSearch = () => {
    $('body').toggleClass('has-open-search')
    hasOpenSearch = !hasOpenSearch
    if (hasOpenMenu) {
      $('body').removeClass('has-open-menu')
      hasOpenMenu = false
    }
    $('html').toggleClass('has-overlay', hasOpenMenu || hasOpenSearch)
    temporarilyPreventMenuHiding()
  }

  const toggleMenu = () => {
    $('body').toggleClass('has-open-menu')
    hasOpenMenu = !hasOpenMenu
    if (hasOpenSearch) {
      $('body').removeClass('has-open-search')
      hasOpenSearch = false
    }
    $('html').toggleClass('has-overlay', hasOpenMenu || hasOpenSearch)
    temporarilyPreventMenuHiding()
  }

  $('.masthead__search-toggle').on('click', e => {
    e.preventDefault()
    e.stopImmediatePropagation()
    toggleSearch()
  })

  $('.masthead__menu-toggle').on('click', e => {
    e.preventDefault()
    e.stopImmediatePropagation()
    toggleMenu()
  })

  $(document).on('click', e => {
    const $target = $(e.target)

    if (hasOpenSearch && $target.closest('.masthead__search').length <= 0) {
      toggleSearch()
    }
    if (hasOpenMenu && $target.closest('.masthead__menu-wrap').length <= 0) {
      toggleMenu()
    }
  })

  toggles('.masthead__menu-list-item.has-dropdown', '.is-toggle')
  toggles('.masthead__menu-list-item.has-children', '.is-toggle')

  // TRACKING
  $('.masthead__menu-list-item').on('click', e => {
    const $target = $(e.currentTarget)
    const text = []
    e.stopImmediatePropagation()

    $target
      .parents('.masthead__menu-list-item')
      .find('> a, > span')
      .each((i, el) => text.push($(el).text()))
    $target.find('> a, > span').each((i, el) => text.push($(el).text()))

    ga('Menu', 'click', text.join(' > '))
  })
}
