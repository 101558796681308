import $ from 'jquery';
import { supportsIntersectionObserver } from '../helpers';

export default function($parent) {
  $parent.find('.js-animate-children').each((i, el) => {
    const animations = [];
    const $el = $(el);
    const $children = $el.find('[class*="js-animate-"]');
    
    $children.each((j, child) => {
      const $child = $(child);
      const dur = $child.attr('data-anim-dur') || 1000;

      if ($child.hasClass('js-animate-fade')) {
        $child.css('opacity', 0);
        animations.push(() => $child.animate({ opacity: 1 }, dur).promise());
      }
      if ($child.hasClass('js-animate-dash')) {
        const childlength = Math.ceil(child.getTotalLength())

        $child.css({
          'stroke-dashoffset': childlength,
          'stroke-dasharray': childlength
        });
        animations.push(() =>
          $child.animate({ 'stroke-dashoffset': 0 }, dur).promise()
        );
      }
    });

    $el.css('opacity', 1);

    if (supportsIntersectionObserver()) {
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const { isIntersecting, intersectionRatio } = entry;

          if (isIntersecting === true || intersectionRatio > 0.3) {
            observer.disconnect();
            observer = null;

            // load all animations
            animations.reduce((promiseChain, produceAnimation) => {
              return promiseChain.then(chainResults =>
                produceAnimation().then(currentResult => [
                  ...chainResults,
                  currentResult
                ])
              );
            }, Promise.resolve([]));
          }
        });
      }, {});
      observer.observe(el);
    } else {
      // load all animations
      Promise.all(animations.map(produceAnimation => produceAnimation()));
    }
  });
}
