import $ from 'jquery'
import exitpopup from './vendor'

export default function() {
  if (!!wordpress_constants.exitpop) {
    const $exitmodal = $('#exitpopup-modal')
    const { cookieExpire, click_outside } = wordpress_constants
    exitpopup($exitmodal[0], {
      aggressive: true,
      timer: 0,
      sensitivity: 20,
      delay: 0,
      sitewide: true,
      cookieExpire,
    })

    if (!!click_outside) {
      $('body').on('click', function() {
        $exitmodal.hide()
      })
    }
    $exitmodal
      .find('.exitpopup-modal-footer, .exitpopup__close, .js-close')
      .on('click', function() {
        $exitmodal.hide()
      })
    $exitmodal.find('.exitpopup-modal-window').on('click', function(e) {
      e.stopPropagation()
    })
  }
}
