let hasGa = undefined
function testForGa() {
  return new Promise((resolve, reject) => {
    if (!window.ga) reject(false)
    window.ga(function(tracker) {
      if (typeof tracker === 'undefined') return reject(false)
      if (typeof tracker.get('clientId') !== 'undefined') return resolve(true)
      return reject(false)
    })
  })
}

function csvJSON(csv) {
  const lines = csv.split('\n')

  return lines.map(line =>
    line.split(',').map(item => {
      const content = item.split("'")
      return content.length > 1 ? content[1] : parseInt(content[0])
    })
  )
}

function ga(
  eventCategory,
  eventAction,
  eventLabel = null,
  eventValue = null,
  fieldsObject = {}
) {
  const trackGa = () => {
    window.ga(
      'send',
      'event',
      eventCategory,
      eventAction,
      eventLabel,
      eventValue,
      fieldsObject
    )
  }
  const trackGtag = () => {
    if (window.gtag !== void 0) {
      if (eventLabel !== null && typeof eventLabel === 'object') {
        const { hitCallback, ...fields } = eventLabel
        window.gtag('event', eventAction, {
          event_category: eventCategory,
          value: eventValue,
          event_callback: hitCallback,
          ...fields,
          ...fieldsObject
        })
      } else {
        window.gtag('event', eventAction, {
          event_category: eventCategory,
          event_label: eventLabel,
          value: eventValue,
          ...fieldsObject
        })
      }
    }
  }
  if (typeof hasGa === 'undefined') {
    testForGa()
      .then(() => {
        hasGa = true
        trackGa()
      })
      .catch(() => {
        hasGa = false
        trackGtag()
      })
  } else if (hasGa) trackGa()
  else trackGtag()
}

export { ga, csvJSON }
