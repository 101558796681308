import $ from 'jquery'

export default function(toggleSelector, eventTarget) {
  let hasOpenMenu = false

  const $dropdowns = $(toggleSelector)

  $dropdowns.on('click', e => {
    if ($(e.target).closest(eventTarget).length <= 0) return

    const item = e.currentTarget
    const clickedBefore = $.data(item, 'clicked')

    e.stopImmediatePropagation()
    if (!clickedBefore) {
      e.preventDefault()
      $dropdowns.removeClass('is-clicked').each((i, el) => {
        $.data(el, 'clicked', false)
      })
      $.data(item, 'clicked', true)
      $(item).addClass('is-clicked')
      hasOpenMenu = true
    }
    else {
      $.data(item, 'clicked', false)
      $(item).removeClass('is-clicked')
      hasOpenMenu = false
    }
  })

  $(document).on('click', e => {
    if (hasOpenMenu) {
      if ($(e.target).closest(toggleSelector).length <= 0) {
        $dropdowns.removeClass('is-clicked').each((i, el) => {
          $.data(el, 'clicked', false)
        })
        hasOpenMenu = false
      }
    }
  })
}
