import './config'
import $ from 'jquery'
import { supportsIntersectionObserver } from './helpers'
import { ga } from './utility'

import masthead from './masthead'
import animate from './animate'
import exitpopup from './exitpopup'
import Ready from './ready'
//import infopanel from './infopanel'

let openInterfaceElements = false

const $contact_slider = $('.contact-slider-wrap')
const $match_height = $('.js-match-height')
const $js_chart = $('.js-chart')
const $tooltip = $('.js-tooltip')
const $delayed_animations = $('.js-anim-delay')
const $chart_rings = $('[data-stroke-dashoffset]')

$('summary').on('click', e => {
  if (e.target.closest('.cmplz-category,.cmplz-dropdown')) return
  e.preventDefault()
  const $summary = $(e.currentTarget)
  const $detail = $summary.closest('details')
  const $content = $detail.find('[data-details]')
  const currentlyOpen = !!$detail.attr('open')

  if (!currentlyOpen) {
    $content.hide()
    $detail.attr('open', true)
    $content.slideDown(400)
  }
  else {
    $content.slideUp(400, () => $detail.removeAttr('open'))
  }

  $summary.find('[data-arrow]').toggleClass('-rotate-90', currentlyOpen)
})

masthead()
//infopanel()
;(async function() {
  const $wrap = $('.js-eqcss')

  if ($wrap.length > 0) {
    try {
      await import('eqcss')
    } catch (e) {
      console.log(e)
    }
  }
})()
;(async function() {
  const $wrap = $('.carousel-container')

  if ($wrap.length > 0) {
    try {
      const charts = await import('./slider')
      charts.default($wrap)
    } catch (e) {
      console.log(e)
    }
  }
})()
;(async function() {
  if ($tooltip.length > 0) {
    try {
      const charts = await import('./tooltip')
      charts.default($tooltip)
    } catch (e) {
      console.log(e)
    }
  }
})()
;(async function() {
  const $wrap = $('.steps-container')

  if ($wrap.length > 0) {
    try {
      const charts = await import('./steps')
      charts.default($wrap)
    } catch (e) {
      console.log(e)
    }
  }
})()
;(async function() {
  const $links = $('a[href^="#wizard"]')

  if ($links.length > 0) {
    try {
      const wizard = await import('./wizard')
      wizard.default($links)
    } catch (e) {
      console.log(e)
    }
  }
})()
;(function() {
  let loaded = false
  const $videos = $('.embedded-video')
  const loadPlayer = async () => {
    loaded = true
    try {
      let items = []
      const videoplayer = await import('./videoplayer')
      $videos.each((i, item) => {
        const $item = $(item)
        const $link = $item.find('.embedded-video__link')
        const href = $link.prop('href')
        const fallback = $item.find('.embedded-video__fallback')
        items.push([item, href, fallback.length > 0 ? fallback.html() : null])
      })
      videoplayer.default(items)
    } catch (error) {
      console.log(error)
    }
  }

  if ($videos.length > 0) {
    $('.embedded-video').on('click', () => {
      if (!loaded) loadPlayer()
    })

    if (supportsIntersectionObserver()) {
      let observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              loadPlayer()
              // only execute once
              $videos.each((i, elem) => observer.unobserve(elem))
            }
          })
        },
        { rootMargin: '0px 0px 200px 0px' }
      )

      $videos.each((i, elem) => observer.observe(elem))
    } else {
      loadPlayer()
    }
  }
})()
;(async function() {
  if ($match_height.length > 0) {
    try {
      await import('jquery-match-height')
      $match_height.matchHeight()
    } catch (e) {
      console.log(e)
    }
  }
})()
;(async function() {
  if ($js_chart.length > 0) {
    try {
      const charts = await import('./chart')
      charts.default($js_chart)
    } catch (e) {
      console.log(e)
    }
  }
})()

if ($delayed_animations.length > 0) {
  let anim_delay = 0
  $delayed_animations.each((i, elem) => {
    $(elem).css('animation-delay', anim_delay + 'ms')
    anim_delay += 150
  })
}

// animate ring charts
if ($chart_rings.length > 0) {
  if (supportsIntersectionObserver()) {
    $chart_rings.each((j, ring) => {
      const $parent = $(ring).closest('.chart')
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const { isIntersecting, intersectionRatio } = entry
          if (isIntersecting === true || intersectionRatio > 0) {
            animateRing(j, ring)
            observer.disconnect()
            observer = null
          }
        })
      }, {})
      observer.observe($parent[0])
    })
  } else {
    $chart_rings.each(animateRing)
  }
}

;(async function() {
  if ($('#mc-embedded-subscribe-form').length > 0) {
    try {
      await import('./mailchimp')
    } catch (e) {
      console.log(e)
    }
  }
})()

$('.graph.has-bars').each((i, el) => {
  let highest = 0
  let percentages = []
  const $graph = $(el)
  const $bars = $graph.find('.graph__bar')

  $bars.each((j, bar) => {
    const percent = $(bar).attr('data-percent')
    percentages[percentages.length] = [$(bar), percent]
    if (percent > highest) {
      highest = percent
    }
  })

  if (supportsIntersectionObserver()) {
    $bars.each((j, bar) => {
      let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          const { isIntersecting, intersectionRatio } = entry

          if (isIntersecting === true || intersectionRatio > 0) {
            const [$item, percent] = percentages[j]
            $item.css('width', (percent / highest) * 100 + '%')
            observer.disconnect()
            observer = null
          }
        })
      }, {})
      observer.observe(bar)
    })
  } else {
    percentages.map(([$item, percent]) => {
      $item.css('width', (percent / highest) * 100 + '%')
    })
  }
})

$('.js-close').on('click', e => {
  e.preventDefault()
  $('body').trigger('click')
})

$(document).on('click', '.glossary__dropdown-toggle', e => {
  $(e.currentTarget)
    .parent()
    .toggleClass('is-open')
})

exitpopup()

let contact_slider_timer = false

$contact_slider.each((i, el) => {
  const $slider = $(el)
  $slider.find('.contact-slider-button').on('click', () => {
    $slider.toggleClass('is-open')
    openInterfaceElements = !openInterfaceElements

    if ($slider.hasClass('is-open')) {
      contact_slider_timer = Date.now()
      ga('Contact Slider', 'open')
    } else {
      ga('Contact Slider', 'close')
      if (contact_slider_timer !== false) {
        ga(
          'Contact Slider',
          'hover',
          'duration in sec',
          Math.ceil((Date.now() - contact_slider_timer) / 1000)
        )
        contact_slider_timer = false
      }
    }
  })
})

animate($(document))

$(document).on('click', e => {
  if (openInterfaceElements) {
    if (!$(e.target).closest('.contact-slider-wrap').length > 0) {
      openInterfaceElements = !openInterfaceElements
      $contact_slider.removeClass('is-open')
      if (contact_slider_timer !== false) {
        ga(
          'Contact Slider',
          'hover',
          'duration in sec',
          Math.ceil((Date.now() - contact_slider_timer) / 1000)
        )
        contact_slider_timer = false
      }
    }
  }
})

// wait till zopim is in DOM
Ready('.zopim[data-test-id="ChatWidgetButton"]', elem => {
  if (typeof ResizeObserver === 'undefined') return false

  const ro = new ResizeObserver(entries => {
    const $entry = $(entries[0].target)
    const marginBottom = parseInt($entry.css('bottom'))
    const entryWidth = $entry.outerWidth()
    const entryHeight = $entry.outerHeight()

    $('.contact-slider-button').css({
      'margin-right': entryWidth + 20,
      height: entryHeight
    })

    if (!isNaN(marginBottom) && marginBottom > 0) {
      $('.contact-slider-button').css('margin-bottom', marginBottom)
    }
  })

  // Only observe the 2nd box
  ro.observe(elem)
})

// analytics
$(document).on('click', '.steps-nav__item', e => {
  ga(
    'Steps Interaction',
    'click',
    $(e.currentTarget)
      .find('.step__title')
      .text()
  )
})

$(document).on('click', '.wpcf7-submit', () => {
  ga('Contact Form', 'send')
})

$(document).on('click', '#exitpopup-modal', e => {
  const $el = $(e.target)
  if ($el.closest('.js-close') || $el.hasClass('underlay')) {
    ga('Exit Popup', 'close')
  }
  if ($el.hasClass('wpcf7-submit')) {
    ga('Exit Popup', 'send')
  }
})

$(document).on('click', '.cmplz-btn.cmplz-accept', e => {
  ga('Privacy Popup', 'accepted')
})

$(document).on('click', '.cmplz-btn.cmplz-deny', e => {
  ga('Privacy Popup', 'refused')
})

$(document).on('click', '.cmplz-btn.cmplz-view-preferences', e => {
  ga('Privacy Popup', 'preferences')
})

$(document).on('click', '.cmplz-documents', e => {
  ga('Privacy Popup', 'info')
})

// $(document).on('setCookieNotice', e => {
//   const value =
//     typeof e.value !== 'undefined'
//       ? e.value
//       : typeof e.detail !== 'undefined' && e.detail.value
//   const accepted = value === 'true'
//   ga('Privacy Popup', accepted ? 'accepted' : 'refused', {
//     hitCallback: () => {
//       if (typeof value !== 'undefined') {
//         var url = window.location.protocol + '//',
//           hostname = window.location.host + '/' + window.location.pathname

//         if (cnArgs.cache === '1') {
//           url =
//             url +
//             hostname.replace('//', '/') +
//             (window.location.search === ''
//               ? '?'
//               : window.location.search + '&') +
//             'cn-reloaded=1' +
//             window.location.hash

//           window.location.href = url
//         } else {
//           url =
//             url +
//             hostname.replace('//', '/') +
//             window.location.search +
//             window.location.hash

//           window.location.reload(true)
//         }

//         return
//       }
//     }
//   })
// })

// $(document).on('click', '#cn-more-info', () => {
//   ga('Privacy Popup', 'info')
// })

// track all mailto links
$('a[href^="mailto:"]').click(function() {
  ga('Contact', 'Click', 'mailto')
})

document.addEventListener(
  'wpcf7mailsent',
  function(event) {
    ga('Contact', 'Form', 'submit')
    if (typeof fbq === 'function') fbq('track', 'Lead')
  },
  false
)

// function ga(
//   eventCategory,
//   eventAction,
//   eventLabel = null,
//   eventValue = null,
//   fieldsObject = null
// ) {
//   if (window.ga !== void 0) {
//     window.ga(
//       'send',
//       'event',
//       eventCategory,
//       eventAction,
//       eventLabel,
//       eventValue,
//       fieldsObject
//     )
//   }
// }

// function ga(
//   eventCategory,
//   eventAction,
//   eventLabel = null,
//   eventValue = null,
//   fieldsObject = {}
// ) {
//   if (window.gtag !== void 0) {
//     if (typeof eventLabel === 'object') {
//       const {hitCallback, ...fields} = eventLabel
//       window.gtag('event', eventAction, {
//         'event_category': eventCategory,
//         'value': eventValue,
//         'event_callback': hitCallback,
//         ...fields,
//         ...fieldsObject,
//       })
//     }
//     else {
//       window.gtag('event', eventAction, {
//         'event_category': eventCategory,
//         'event_label': eventLabel,
//         'value': eventValue,
//         ...fieldsObject,
//       })
//     }
//   }
//   else if (window.ga !== void 0) {
//     window.ga(
//       'send',
//       'event',
//       eventCategory,
//       eventAction,
//       eventLabel,
//       eventValue,
//       fieldsObject
//     )
//   }
// }

function animateRing(i, el) {
  const $item = $(el)
  const full = $item.attr('stroke-dasharray')
  const section = $item.attr('data-stroke-dashoffset')

  $item.animate(
    { 'stroke-dashoffset': parseFloat(section) },
    {
      duration: 2000,
      step: function(now) {
        $(this).attr('stroke-dashoffset', now)
      }
    }
  )
}
